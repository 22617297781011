/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import '@angular/cdk/overlay-prebuilt.css';
@import url(https://fonts.googleapis.com/css?family=Lato:700);
.ribbon {
  width: 190px;
  height: 190px;
  overflow: hidden;
  position: absolute;
  z-index: 9999;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 275px;
  padding: 15px 0;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  color: #fff;
  font: 700 15px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-blue {
    background-color: #3498db;
}
.ribbon-green {
    background-color: #178f57;
}
.ribbon-red {
    background-color: #960000;
}
  /* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -20px;
    top: 58px;
    transform: rotate(-45deg);
  }
  .cdk-overlay-pane {
    background-color: #FFFFFF !important;
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    background-color: #FFFFFF !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  }

  .painel {
    width: 360px !important;;
    margin-top: 5px !important;;
    background-color: #ffffff !important;
    border-radius: 0.75rem !important;
    padding: 15px !important;;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }